.apply_form {
  display: flex;
  flex-wrap: wrap;
}

.grade {
  width: 90%;
  margin-top: 20px;
  padding: 10px;
  background-color: $color-white;
  border: 1px solid $color-black;
}
.consent {
  width: 90%;
  margin-bottom: 2rem;

  p {
    font-size: 12px;
  }
}
.class {
  display: flex;
  align-items: baseline;
}
.class_section {
  display: block;
  margin-top: 20px;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 600;
}
legend {
  display: block;
  margin-top: 20px;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 600;
}
label {
  display: flex;
  margin-top: 10px;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 600;
}
.inline_label {
  margin: 20px 0 15px 10px;
}
.inline_consent {
  font-weight: 400;
  margin: 10px 0 20px 10px;
}

.apply_input {
  margin-top: 2px;
  border: 1px solid $color-black;
  background-color: $color-white;
  font-family: $font-primary;
  font-size: 14px;
  width: 90%;
  padding: 10px;
  border-radius: none;
}
.post_group {
  display: inline-flex;
  flex-direction: column;
  width: 47.5%;
}

.apply_textarea {
  margin-top: 10px;
  width: 90%;
  height: 150px;
  font-family: $font-primary;
  font-size: 14px;
  border: 1px solid $color-black;
  border-radius: none;
}

.form_h2 {
  margin-top: 20px;
  font-family: $font-primary;
}

.apply_btn {
  width: 90%;
  padding: 10px;
  font-family: $font-primary;
  font-size: 14px;
  text-align: center;
  color: $color-white;
  background-color: $color-primary;
  border: none;
}
.apply_btn:hover {
  background-color: $color-secondary;
}
