.about_container {
  background-color: $color-background;
  padding: 2rem;
  margin: 0 0 4rem;
}
.about_col {
  margin: 2rem 0 0;
}

.teacher_col {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding: 20px;
  background-color: $color-background;
}
.profile_img {
  width: 150px;
  height: 208px;
}
.profile_info {
  margin: 20px 0;
  align-items: left;
}
.profile_info-meta {
  margin-top: 2rem;
}
.profile_info p {
  font-size: 14px;
}

@media (min-width: 463px) {
  .teacher_col {
    flex-wrap: nowrap;
  }
  .profile_info {
    margin: 20px;
    align-items: center;
  }
}
