// Colors
$color-primary: #93278f;
$color-secondary: #f7931e;
$color-background: #f9f9f9;
$color-footer: #93278f;
$color-white: #ffffff;
$color-black: #000000;
$color-shadow: rgba(0, 0, 0, 0.1);
$color-link: #f7931e;

// Fonts

$font-primary: "Montserrat", sans-serif;
$font-secondary: "Love Ya Like A Sister", cursive;
