.section_container {
  margin: 2rem 0 0;
  width: 100%;
  padding: 2rem;
}
.section_container1 {
  margin: 0;
  width: 100%;
  background-color: $color-background;
  padding: 2rem;
}

.h2_margin {
  margin-top: 20px;
}
.section_img {
  width: 90%;
  margin: 2rem 0;
}
.purple_btn {
  border: none;
  background-color: $color-primary;
  color: $color-white;
  padding: 15px;
  width: 250px;
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 0 0;
}
.purple_btn:hover {
  background-color: $color-secondary;
}
.about_btn {
  border: none;
  background-color: $color-primary;
  color: $color-white;
  padding: 15px;
  width: 250px;
  font-size: 18px;
  font-weight: 400;
  margin: 1rem auto 3rem;
  text-align: center;
}
.about_btn:hover {
  background-color: $color-secondary;
}
@media (min-width: 768px) {
  .section_col {
    text-align: end;
    align-self: center;
  }
}
