// CREATE POST
.post_form {
  display: block;
  font-family: $font-primary;
  margin: 20px;
}
.post_input {
  font-family: $font-primary;
  border: 1px solid $color-black;
  font-size: 16px;
  width: 90%;
  padding: 10px;
  border-radius: none;
}
.post_textarea {
  font-family: $font-primary;
  font-size: 16px;
  padding: 10px;
  border: 1px solid $color-black;
  width: 90%;
  height: 200px;
  margin-top: 10px;
  border-radius: none;
}
.post_select {
  width: 90%;
  padding: 10px;
  font-family: $font-primary;
  font-size: 16px;
  border: 1px solid $color-black;
  margin: 5px 0 10px;
  border-radius: none;
}
.post_btn {
  border: none;
  width: 90%;
  background-color: $color-primary;
  color: $color-white;
  padding: 10px;
  font-family: $font-primary;
  font-size: 16px;
}
.post_btn:hover {
  background-color: $color-secondary;
}
// POST LIST
hgroup {
  margin: 20px 0 0 20px;
}
.published_posts {
  margin: 0px 0 20px 0;
}
.published_list {
  display: flex;
  width: 90%;
}
.post_list {
  list-style: none;
  width: 90%;
}

.post_list li {
  border: 1px solid $color-secondary;
  background-color: $color-white;
  padding: 10px;
}
.post_list a {
  color: $color-black;
}

.post_list li:hover {
  border: 1px solid $color-secondary;
  background-color: $color-secondary;
  color: $color-white;
}

// DELETE POST
.delete_button {
  color: $color-black;
  border: none;
  background: none;
  font-size: 21px;
  align-self: baseline;
}
.delete_button:hover {
  color: $color-secondary;
}
.error_delete,
.error_img {
  font-family: $font-primary;
  font-size: 12px;
  color: #ff0000;
}
.success_delete {
  font-family: $font-primary;
  font-size: 12px;
  color: #8cc63f;
}
