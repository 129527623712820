h1 {
  font-family: $font-secondary;
  font-size: 36px;
  color: $color-black;
  margin: 2rem 0 1rem;
  text-align: center;
}
.galleri {
  margin: 0;
}
h2 {
  font-family: $font-secondary;
  font-size: 28px;
  color: $color-black;
}
h3 {
  font-family: $font-secondary;
  font-size: 21px;
  font-weight: 600;
  color: $color-primary;
}
h4 {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 500;
  color: $color-black;
  margin: 1rem 0;
}
h5 {
  font-family: $font-primary;
  font-size: 18px;
  font-weight: 700;
  color: $color-white;
}
h6 {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 600;
  color: $color-white;
}

a {
  color: $color-link;
  text-decoration: none;
  font-family: $font-primary;
  font-size: 14px;
}
.mail_link {
  color: $color-black;

  &:hover {
    color: $color-link;
    text-decoration: underline;
  }
}
p {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

li {
  font-family: $font-primary;
  font-size: 16px;
  font-weight: 400;
}
footer p {
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  list-style-type: none;
}
@media (min-width: 992px) {
  h1 {
    font-size: 48px;
  }
}
