.fa_icon1 {
  font-size: 30px;
  margin: 1rem 0 0;
  color: $color-white;
}

h5 {
  margin-top: 50px;
  margin-bottom: 0px;
}

.margin_footer {
  margin-top: 50px;
}
.footer_link {
  display: flex;
  flex-wrap: wrap;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 400;
  color: $color-white;
  margin-top: 10px;
}
.footer_link:hover {
  color: $color-white;
  font-weight: 500;
}
.contact_links {
  display: inline-flex;
  flex-wrap: wrap;
  color: $color-white !important;
  font-size: 14px;
}
.contact_adress {
  margin-top: 20px;
  font-size: 14px;
}
.line {
  margin: 20px 0 0 0;
  width: 100%;
  border-top: 0.5px solid $color-white;
}
footer {
  p:first-of-type {
    margin-top: 10px;
  }
}

.copyright {
  margin: 15px;
  text-align: end;
}
