.edit_fieldset {
  width: 100%;
  text-align: center;
}
.edit_input {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
}
.edit_textarea {
  height: 200px;
  width: 90%;
}
.update_btn {
  width: 90%;
  padding: 10px;
  font-family: $font-primary;
  font-size: 14px;
  text-align: center;
  color: $color-white;
  background-color: $color-primary;
  border: none;
}
.update_btn:hover {
  background-color: $color-secondary;
}
.success {
  font-family: $font-primary;
  font-size: 14px;
  color: #8cc63f;
}
