.search_col {
  display: flex;
  margin-left: 30px;
}
.searchbar {
  width: 300px;
  padding: 7px;
  border: 1px solid $color-black;
  border-radius: none;
}
.blogposts {
  margin-top: 20px;
}
.post_card {
  border-radius: 0;
  border: none;
  background-color: $color-background;
}
.post_img {
  width: 100%;
  border-radius: 0;
}

.post_title {
  font-family: $font-secondary;
  font-size: 24px;
  color: $color-black;
}
// RECENT POSTS
.post_card_frontpage {
  border-radius: 0;
  border: none;
  background-color: $color-white;
}

.post_title_frontpage {
  font-family: $font-secondary;
  font-size: 24px;
  color: $color-black;
  margin: 10px 0 0 0;
}
.post_date {
  font-family: $font-primary;
  font-size: 14px;
  color: $color-primary;
  margin: 10px 0 5px 0;
}
.post_content {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;

  @media (min-width: 700px) {
    & {
      width: 60%;
    }
  }
}
.single_post_img {
  margin: 50px 0 20px 0;
}
.single_post_title {
  display: flex;
}
