html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  background: $color-white;
  margin: 0;
}
.body-container {
  width: 100%;
  margin: 0 auto;
}
.background {
  background-color: $color-background;
  z-index: 10;
}
.header_img {
  width: 100%;
}
.header_img-front {
  display: none;

  @media (min-width: 600px) {
    display: block;
    width: 100%;
  }
}
.header_img-mobile {
  display: block;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
}
.boxesloader {
  margin: auto;
}
.intro {
  width: 70%;
  margin: auto;
  text-align: center;

  @media (min-width: 700px) {
    & {
      width: 60%;
    }
  }
}
.quote {
  font-family: $font-primary;
  color: $color-black;
  font-size: 18px;
  padding: 20px;
  border-radius: 20%;
  margin: 20px;
}
.bqstart {
  font-family: $font-secondary;
  float: left;
  height: 45px;
  margin-top: -60px;
  margin-left: -40px;
  margin-bottom: -50px;
  font-size: 700%;
  color: $color-secondary;
}
.bqend {
  font-family: $font-secondary;
  float: right;
  height: 25px;
  margin-top: -30px;
  font-size: 700%;
  color: $color-secondary;
}
footer {
  background-color: $color-footer;
  color: $color-white;
  position: relative;
  margin-top: 150px;
}
footer::before {
  content: "";
  display: block;
  width: 100%;
  height: 150px;
  background-color: $color-footer;
  transform: skew(0deg, -3deg);
  position: absolute;
  top: 0px;
  left: 0px;
  transform-origin: left;
  z-index: -1;
}

.wrapper {
  flex: 1 0 auto;
}
